import type { AsyncData, NuxtError } from "#app";
import { Block, type BlockEntity } from "~/src/api";

let refreshTimer: NodeJS.Timeout | null = null;
let existingAsyncData: null | AsyncData<
  BlockEntity[],
  NuxtError<unknown> | null
> = null;

// This thing will be called MANY times at the same time,
// We need to take extra defensive measures to make sure we don't fire multiple requests at once
export function useBlockedUsers(options?: { doImmediateRefresh?: boolean }) {
  if (!process.client) {
    return {
      status: ref("idle"),
      data: ref([]),
      blockedUsers: ref([]),
      toggleBlockAction: {
        action: () => {},
        pending: true,
      },
      error: ref(null),
      refresh: () => {},
    };
  }

  const isLoggedIn = useIsLoggedIn();
  const nuxtApp = useNuxtApp();
  const auth = nuxtApp.$auth();
  const app = nuxtApp.$app();

  if (!refreshTimer) {
    refreshTimer = setInterval(
      () => {
        refresh();
      },
      1 * 1000 * 60,
    );
  }

  if (!existingAsyncData) {
    existingAsyncData = useAsyncData(
      `blocked-users`,
      async () => {
        if (!isLoggedIn) return [];

        const allBlocks: BlockEntity[] = [];

        let offset = 0;

        while (true) {
          const list = await Block.listBlocks(
            {
              includes: ["user"],
              offset,
              limit: 100,
            },
            await getTokenOrThrow(),
          );

          allBlocks.push(...list.data);
          if (list.meta.total <= list.data.length + offset) break;
          offset += list.meta.limit;
        }

        return allBlocks;
      },
      {
        default: () => [] as BlockEntity[],
      },
    );
  }

  const { status, data, error, refresh } = existingAsyncData;

  if (options?.doImmediateRefresh) {
    refresh();
  }

  const blockedUsers = computed(() =>
    data.value.map((block) => getTargetUser(block)).filter(onlyTruthys),
  );

  function getTargetUser(block: BlockEntity) {
    assertDefined(auth?.userEntity);
    const user = block.relationships.find(
      (rel) => rel.id !== auth.userEntity?.id,
    );
    if (!user) return undefined;
    return user;
  }

  const toggleBlockAction = useAction2(async (id: string) => {
    await executeWithNotificationOnError(async () => {
      if (isBlocked(id)) {
        if ((await confirmUnblockUser()) !== "confirm") return;
        await Block.removeBlock(id, await getTokenOrThrow());
        app?.notify({
          preset: "success.plain",
          detail: "User has been unblocked",
          timer: 3000,
        });
        removeBlockedUserFromData(id);
      } else {
        if ((await confirmBlockUser()) !== "confirm") return;
        const block = await Block.createBlock(id, await getTokenOrThrow());
        app?.notify({
          preset: "success.plain",
          detail: "User has been blocked",
          timer: 3000,
        });
        addBlockedUserToData(block);
      }
    });
  });

  async function confirmBlockUser() {
    return await app?.prompt("Are you sure you want to block this user?", {
      icon: "confused",
      buttons: {
        cancel: {
          buttonType: "secondary",
          buttonText: "Cancel",
        },
        confirm: {
          buttonType: "danger",
          buttonText: "Block",
        },
      },
    });
  }

  async function confirmUnblockUser() {
    return await app?.prompt("Are you sure you want to unblock this user?", {
      icon: "confused",
      buttons: {
        cancel: {
          buttonType: "secondary",
          buttonText: "Cancel",
        },
        confirm: {
          buttonType: "primary",
          buttonText: "Unblock",
        },
      },
    });
  }

  const removeBlockedUserFromData = (id: string) => {
    const dataIndex = data.value.findIndex(
      (block) => getTargetUser(block)?.id === id,
    );
    if (dataIndex !== undefined && dataIndex !== -1) {
      data.value.splice(dataIndex, 1);
    }
  };

  const addBlockedUserToData = (block: BlockEntity) => {
    data.value.push(block);
  };

  function isBlocked(id: string) {
    return blockedUsers.value.some((u) => u.id === id);
  }

  return {
    status,
    data,
    blockedUsers,
    toggleBlockAction,
    error,
    refresh,
  };
}
